<template>
  <div v-if="getOrderItem && getOrderItem.id && getUser && getUser.id" class="view-requests-body body">
    <div class="container">
      <div class="body__header">
        <div class="body__header-top top">
          <p class="top__title">{{ $t('request', {data:  getOrderItem.id}) }}</p>
          <div class="top__status">
            <div class="chip" :style="{ backgroundColor: getStatus(getOrderItem.status).color}" v-if="!isChangeStatus"/>
            <p v-if="!isChangeStatus">{{ $t(getStatus(getOrderItem.status).text) }}</p>
            <UiSelect v-model="selectedStatus" :options="changedStatus" v-if="isChangeStatus" @input="changeOrderStatus" />
            <img
                alt=""
                src="@/assets/svg/admin/edit-default.svg"
                v-if="(getOrderItem.status === 'Accepted' || getOrderItem.status === 'Remoderation') && !isChangeStatus && (getUser.role === 'Moderator' || getUser.role === 'Admin')"
                class="top__status-edit"
                @click="isChangeStatus=true"
            />
          </div>
        </div>
        <div class="body__header-bottom bottom">
          <div class="bottom__action">
            <ui-button color="error" @click="likeOrder" v-if="getOrderItem.createdBy.id !== getUser.id">
              <img src="@/assets/svg/icon-like-white.svg" alt="" />
              <p>
                <span v-if="!getOrderItem.isLikedByMe && getOrderItem.createdBy.id !== getUser.id">
                  {{ $t('i-care') }} ({{ getOrderItem.likesCount }})
                </span>
                <span v-else>{{ getOrderItem.likesCount }}</span>
              </p>
            </ui-button>
            <ui-button v-if="getOrderItem?.isSubscribedByMe && getOrderItem.createdBy.id !== getUser.id" @click="unSubscribe">
              <img src="@/assets/svg/icon-subway.svg" alt="" />
              <p>{{ $t('unsubscribe') }}</p>
            </ui-button>
            <ui-button
                v-if="!getOrderItem?.isSubscribedByMe && getOrderItem.createdBy.id !== getUser.id"
                @click="subscribe"
                :disabled="getOrderItem.status === 'Closed'"
            >
              <img src="@/assets/svg/icon-subway.svg" alt="" />
              <p>{{ $t('subscribe-for-updates') }}</p>
            </ui-button>
          </div>
          <router-link :to="`${ getUser?.id === getOrderItem.createdBy.id ? '/profile' : '/user/' + getOrderItem.createdBy.id }`" v-if="getOrderItem.createdBy" class="bottom__user user">
            <div class="user-left">
              <img class="user-left__avatar" :src="getUserAvatar(getOrderItem.createdBy.avatar, true)" alt="" />
              <div class="user-left__info">
                <h2 class="user-left__info-nickname">{{ getOrderItem.createdBy.login }}</h2>
                <p class="user-left__info-role"><img src="@/assets/svg/roles/active.svg" alt=""/>{{ $t('eco-activist') }}</p>
              </div>
            </div>
            <p class="user-chip">{{ getOrderItem.createdBy.score }}</p>
          </router-link>
        </div>
      </div>
      <div class="body__main">
        <a class="body__main-concat" href="mailto:moderator@ecophone.pro">{{ $t('concat-moderator') }}</a>
        <div :class="['body__main-photos', { 'activist-photos': getOrderItem.createdBy.id === getUser.id}]">
          <ViewRequestsBodyPhotos :data="getOrderItem"/>
        </div>
        <div class="body__main-problems">
          <DescriptionProblems :data="getOrderItem" />
        </div>
      </div>
    </div>
    <view-requests-side-bar />
<!--    <div v-if="getOrderItem && getUser.id" ref="body" class="body__content">-->
<!--      <view-requests-side-bar @scrollTo="scrollToContent"/>-->
<!--      <div class="body__content-right">-->
<!--        <ViewRequestsBodyPhotos ref="photo" :data="getOrderItem" />-->
<!--        <DescriptionProblems ref="description" :data="getOrderItem" />-->
<!--        <Location ref="location" :data="getOrderItem" />-->
<!--        <AttachedMembers-->
<!--            ref="member"-->
<!--            v-if="!(getUser.role !== 'Admin' && getUser.role !== 'Moderator' && statusParticipationInfo.type !== 'approved') && getOrderItem.status !== 'Moderation' && getOrderItem.status !== 'Rejected'"-->
<!--            :data="getOrderItem"-->
<!--        />-->
<!--        <AttachedFiles-->
<!--            ref="file"-->
<!--            v-if="!(getUser.role !== 'Admin' && getUser.role !== 'Moderator' && statusParticipationInfo.type !== 'approved') && getOrderItem.status !== 'Rejected'"-->
<!--            :data="getOrderItem"-->
<!--        />-->
<!--        <Comment-->
<!--            ref="comment"-->
<!--            v-if="!(getUser.role !== 'Admin' && getUser.role !== 'Moderator' && statusParticipationInfo.type !== 'approved') && getOrderItem.status !== 'Moderation' || getOrderItem.status === 'Rejected'"-->
<!--            :data="getOrderItem"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <ui-alert v-model="showAlert">-->
<!--      <p class="ui-alert__title">Закрыть обращение?</p>-->
<!--      <div class="ui-alert__action">-->
<!--        <ui-button @click="closeOrder" color="error">Закрыть</ui-button>-->
<!--        <ui-button @click="showAlert = false" color="white">Отмена</ui-button>-->
<!--      </div>-->
<!--    </ui-alert>-->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { getStatus, getFullDate } from '@/utils/general'
import { getUserAvatar } from "@/utils/user";
import UiSelect from "../../../ui/UiSelect.vue";
export default {
  name: "ViewRequestsBody",
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    UiSelect,
    ViewRequestsBodyPhotos: () => import('@/components/template/work-requests/ViewRequests/Photos'),
    DescriptionProblems: () => import('@/components/template/work-requests/ViewRequests/DescriptionProblems'),
    UiButton: () => import('@/components/ui/UiButton'),
    ViewRequestsSideBar: () => import('./ViewRequestsSideBar.vue'),

  },

  data() {
    return {
      getStatus,
      getFullDate,
      getUserAvatar,
      componentName: '',
      showAlert: false,
      selectedStatus: '',
      changedStatus: [
        { name: this.$t('remoderation'), value: 'Remoderation' },
        { name: this.$t('open-1'), value: 'Accepted' },
      ],
      isChangeStatus: false
    }
  },


  computed: {
    ...mapGetters(['getOrderItem','getUser']),
    getDate() {
      if (this.getOrderItem.createdAt)
        return this.getFullDate(this.getOrderItem.createdAt).split(',')[0]

      return null
    },
    getTime() {
      if (this.getOrderItem.createdAt)
        return this.getFullDate(this.getOrderItem.createdAt).split(',')[1]

      return null
    },
    statusParticipationInfo() {
      return this.getOrderItem.myParticipationStatus == null ? {type: 'default', buttonText: '', color: '#E21F1F'} :
          this.getOrderItem.myParticipationStatus === 'Request' ? {type: 'pending', buttonText: this.$t('request-sent'), color: '#e77f00'} :
              this.getOrderItem.myParticipationStatus === 'Approved' ? { type: 'approved', buttonText: '', color: '' } :
                  this.getOrderItem.myParticipationStatus === 'Denied' ?  {type: 'denied', buttonText: this.$t('enquiry-to-participate-in-request-has-been-denied'), color: '#810202'} : null
    }
  },

  methods: {
    ...mapActions([
      'sendOrderClose',
      'sendOrderLike',
      'sendOrderDisLike',
      'orderSubscribe',
      'orderUnsubscribe',
      'sendOrderId',
      'orderToRemoderation',
      'sendOrderAccept'
    ]),
    likeOrder() {
      if (this.getOrderItem.isLikedByMe) {
        this.sendOrderDisLike(this.getOrderItem.id)
      } else {
        this.sendOrderLike(this.getOrderItem.id)
      }
    },

    scrollToContent(refName) {
      if (this.$refs[refName]) {
        this.$refs.body.scrollTo({
          top: this.$refs[refName].$el.offsetTop - 50,
          behavior: 'smooth'
        })
      }
    },

    closeOrder() {
      this.sendOrderClose(this.data.id)
      this.showAlert = false
    },

    subscribe() {
      this.orderSubscribe({id: this.getOrderItem.id}).then(() => {
        const { id } = this.$route.params
        this.sendOrderId(id)
      })
    },

    unSubscribe() {
      this.orderUnsubscribe({id: this.getOrderItem.id}).then(() => {
        const { id } = this.$route.params
        this.sendOrderId(id)
      })
    },

    changeOrderStatus() {
      console.log(this.selectedStatus)
      if (this.selectedStatus.value === 'Remoderation') {
        this.orderToRemoderation(this.getOrderItem)
        this.isChangeStatus = false
      } else {
        this.sendOrderAccept(this.getOrderItem.id)
        this.isChangeStatus = false
      }
    }
  },

  mounted () {
    this.selectedStatus = {
      name: this.$t(this.getStatus(this.getOrderItem.status).text),
      value: this.getOrderItem.status,
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 768px) {
    gap: 30px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;

    @media (max-width: 768px) {
      flex-direction: column;
      border-bottom: transparent;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 40px 30px;
      border-bottom: 1px solid #F5F5F5;

      @media (max-width: 1000px) {
        padding: 30px 12px;
      }

      &__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: #343432;
      }

      &__status {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #1B1A1F;

        .chip {
          width: 10px;
          height: 10px;
          background: #CE2121;
          border-radius: 200px;
        }

        &-edit {
          width: 15px;
          height: 15px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }

    .bottom {
      padding: 30px 30px 10px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;

      @media (max-width: 1000px) {
        padding: 30px 12px;
        flex-direction: column;
        gap: 20px;
      }

      &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        max-width: 630px;
        width: 100%;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        @media (max-width: 1000px) {
          max-width: 100%;
        }
      }

      &__user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #FFFFFF;
        padding: 5px 10px 5px 5px;
        border: 1px solid #D9D9D9;
        box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
        border-radius: 200px;
        max-width: 600px;

        @media (max-width: 1000px) {
          max-width: 100%;
        }

        .user-left {
          display: flex;
          gap: 10px;

          &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            border: 1px solid #CE2121;
          }

          &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;

            &-nickname {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              color: #343432;
            }

            &-role {
              display: flex;
              align-items: center;
              gap: 5px;
              font-weight: 400;
              font-size: 12px;
              line-height: 11px;
              color: #9A9A9A;

              img {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
        .user-chip {
          padding: 14px 11px;
          background: #E21F1F;
          box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
          border-radius: 200px;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #FFFFFF;
          height: 40px;
        }
      }
    }
  }
  &__main {
    display: flex;
    gap: 44px;
    padding: 30px 30px 70px;
    background: #FFFFFF;
    border-radius: 0 0 20px 20px;
    position: relative;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: start;
      padding: 30px 12px 70px 12px;
      gap: 30px;
    }

    @media (max-width: 768px) {
      padding: 30px 12px;
    }

    &-concat {
      position: absolute;
      bottom: 25px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9A9A9A;
      cursor: pointer;

      @media (max-width: 768px) {
        left: 25px;
      }
    }

    &-photos {
      width: 106%;

      &.activist-photos {
        margin-top: -100px !important;
      }

      @media (max-width: 500px) {
        width: 100%;
        max-width: 342px;
      }
    }

    &-problems {
      width: 100%;
    }
  }
}
//.body {
//  width: 100%;
//  position: relative;
//
//  @media(max-width: 1200px){
//    padding-left: 0;
//  }
//
//  ::v-deep .ui-alert__action {
//    display: flex !important;
//    flex-direction: row !important;
//    gap: 8px !important;
//  }
//
//  &__header {
//    background: #FFFFFF;
//    border-radius: 20px 20px 0 0;
//    height: 116px;
//    border-bottom: 1px solid #F5F5F5;
//
//    @media (max-width: 768px) {
//      display: none;
//    }
//
//    .side-bar__header {
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//      padding: 30px 40px 30px 30px;
//      max-width: 426px;
//      width: 100%;
//
//      &-left {
//        display: flex;
//        flex-direction: column;
//        gap: 20px;
//
//        .left-title {
//          font-weight: 400;
//          font-size: 16px;
//          line-height: 15px;
//          color: #343432;
//        }
//
//        .left-subtitle {
//          display: flex;
//          align-items: center;
//          gap: 5px;
//
//          .chip {
//            width: 10px;
//            height: 10px;
//            background: #CE2121;
//            border-radius: 200px;
//          }
//        }
//      }
//
//      &-right {
//        display: flex;
//        flex-direction: column;
//        align-items: flex-end;
//        gap: 20px;
//
//        .right-text {
//          font-weight: 400;
//          font-size: 12px;
//          line-height: 11px;
//          color: #9A9A9A;
//        }
//      }
//    }
//    &-close-request {
//      position: absolute;
//      right: 40px;
//      top: 40px;
//      display: flex;
//      align-items: center;
//      gap: 10px;
//      padding: 3px 12px 3px 3px;
//      background: #E21F1F;
//      box-shadow: 2.05517px 2.05517px 10.2759px rgba(153, 153, 153, 0.25);
//      border-radius: 205.517px;
//      cursor: pointer;
//
//      span {
//        display: flex;
//        justify-content: center;
//        align-items: center;
//        width: 24px;
//        height: 24px;
//        background: #FFFFFF;
//        border-radius: 50%;
//      }
//
//      p {
//        font-weight: 400;
//        font-size: 12px;
//        line-height: 11px;
//        color: #FFFFFF;
//      }
//    }
//  }
//
//  &__content {
//    position: relative;
//    display: grid;
//    grid-template-columns: 426px auto;
//    gap: 20px;
//    align-items: flex-start;
//    overflow-y: auto;
//    height: 100%;
//    padding-bottom: 150px;
//
//    &::-webkit-scrollbar {
//      width: 5px;
//      height: 5px;
//    }
//
//    &::-webkit-scrollbar-thumb {
//      background: #CE2121;
//      border-radius: 16px;
//    }
//
//    &-right {
//      width: 100%;
//      display: flex;
//      flex-direction: column;
//      gap: 30px;
//      padding: 20px 40px 0 0;
//
//      @media (max-width: 768px) {
//        display: none;
//      }
//    }
//  }
//}
//
//::v-deep .side-bar {
//  overflow-y: scroll;
//
//  @media (max-width: 768px) {
//    position: relative;
//    height: auto;
//  }
//
//  &::-webkit-scrollbar {
//    width: 5px;
//    height: 5px;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    background: #CE2121;
//    border-radius: 16px;
//  }
//
//}
</style>
